import axios from "axios"

const API = "motivos-retiros";

const motivosRetiros = {
    listarRetiroCedis: () => axios.get(`${API}/listar-retiro-cedis`),
    listarTrasladoCedis: () => axios.get(`${API}/listar-traslado-cedis`),
    listarRetiroLeecheros: () => axios.get(`${API}/listar-retiro-leecheros`),
    listarDevolucionLeecheros: () => axios.get(`${API}/listar-devolucion-leecheros`),

};

export default motivosRetiros;
