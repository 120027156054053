<template>
    <modal ref="modalDevolucion" titulo="Devolucion a Cedis" :cargando="cargando" @guardar="aplicar_devolucion">
        <ValidationObserver ref="validator">
            <div class="row mx-0">
                <div class="col-5 mb-3">
                    <label class="text-general f-14 pl-3">Cantidad</label>
                    <ValidationProvider v-slot="{errors}" :rules="`required|${producto.cantidad_tipo == 1 ? 'numeric' : 'decimal:2'}`" name="cantidad">
                        <el-input v-model="model.cantidad" size="small" class="w-100" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
                <div class="col mb-3">
                    <ValidationProvider v-slot="{errors}" rules="required" name="cantidad">
                        <label class="text-general f-14 pl-3">Motivo de devolución</label>
                        <el-select v-model="model.motivo" placeholder="Seleccione" class="w-100">
                            <el-option
                            v-for="(item, i) in motivos"
                            :key="i"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
                <div class="col-12 mb-2">
                    <ValidationProvider v-slot="{errors}" rules="required" name="destino">
                        <label class="text-general f-14 pl-3">Cedis Destino</label>
                        <el-select v-model="model.id_cedis_destino" filterable size="small" class="w-100">
                            <el-option
                            v-for="item in select_cedis"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
                <div class="col-12 mt-3">
                    <ValidationProvider v-slot="{errors}" rules="required|max:200" name="observación">
                        <label class="text-general f-14 pl-3"> Observación del traslado </label>
                        <el-input v-model="model.comentario" type="textarea" :rows="4" maxlength="200" show-word-limit />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import {mapGetters} from 'vuex'
import ProductosStocks from '~/services/productos/productos_stocks'
import motivosRetiros from '~/services/configurar/admin/motivosRetiros'


export default {
    data(){
        return{
            cargando:false,
            model:{
                cantidad: null,
                comentario: null,
                id_cedis_destino:null,
                motivo: ''
            },
            producto:{},
            motivos: []
        }
    },
    computed:{
        ...mapGetters({
            id_producto: 'productos/productos/id_producto',
            id_cedis:'cedis/id_cedis',
            cedis:'cedis/cedis'

        }),
        select_cedis(){
            return this.cedis.filter(o=>o.id != 0)
        },
        id_tienda(){
            return Number(this.$route.params.id_tienda)
        }
    },
    methods: {
        toggle(producto){
            this.limpiarForm()
            this.listarMotivos()
            this.producto = producto
            this.$refs.modalDevolucion.toggle();
        },
        async listarMotivos(){
            try {
                const {data} = await motivosRetiros.listarDevolucionLeecheros()
                this.motivos = data
            } catch (e){
                this.error_catch(e)
            }
        },
        async aplicar_devolucion(){
            try {
                const valid = await this.$refs.validator.validate()

                if(valid){
                    this.cargando = true
                    this.model.id_tienda = this.id_tienda
                    this.model.id_producto = this.id_producto
                    const {data} = await  ProductosStocks.devolucion(this.model)
                    this.cargando = false
                    this.notificacion('Mensaje', 'Devolucion realizada', 'success')
                    this.$emit('actualizar')
                    this.$refs.modalDevolucion.toggle();
                }

            } catch (e){
                this.cargando = false
                this.error_catch(e)
            }
        },
        limpiarForm(){
            this.model={
                cantidad: null,
                comentario: null,
                id_cedis_destino: null,
                motivo: ''
            }
        }
    }
}
</script>

<style>

</style>
